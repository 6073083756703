import React from "react"
import { Link } from "gatsby"
import { getLocalizedURL } from '@src/helper';

// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const Page500 = () => {
    
    return (
        <Layout addedClass="page--error page--500">
            <Seo 
            title="Error del servidor | Lupkynis.com" 
            description="" 
            robots="noindex" 
            canonicalURL={"https://www.lupkynis.com"+getLocalizedURL('home', 'es')} />
            <Hero addedClass="bg-light-green hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">Oops!</h1>
                            <h2>Algo salió mal</h2>
                            <p>¡Lo sentimos! Estamos experimentando dificultades técnicas en este momento. Inténtelo de nuevo en algunas horas.</p>
                            <Link to={getLocalizedURL('home', 'es')} className="button">Regresar al inicio</Link>
                        </div>
                        <div className="hero__image">
                            <Image data={{
                                desktopSrc: 'hero-warning-es.png', 
                                mobileSrc: 'hero-warning--mobile-es.png'
                            }} />
                        </div>
                    </div>
                </div>
            </Hero>
        </Layout>
    );
}

export default Page500